import { useEffect, useMemo, useReducer } from "react";
import { useHistory } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import { authReducer } from "../reducers/auth.reducer";
import { AuthDispatchers } from "../models/auth-dispatchers.model";
import { AuthState } from "../models/auth-state.model";

export const useAuth = (): [AuthState, AuthDispatchers] => {
    const history = useHistory();
    const [authState, dispatch] = useReducer(
        authReducer,
        {
            isLoading: true,
            isAuth: false,
        }
    );

    const authDispatchers = useMemo(
        () => ({
            signIn: (name: string) => {
                dispatch({ type: "SIGN_IN", name });
            },
            signOut: async () => {
                try {
                    dispatch({ type: "LOADING" } as any);
                    await Auth.signOut();
                    localStorage.clear();
                    dispatch({ type: "SIGN_OUT" } as any);
                } catch (err) {
                    console.log(err);
                    localStorage.clear();
                    dispatch({ type: "SIGN_OUT" } as any);
                }
            },
            getState() {
                return authState;
            },
            dispatch: (action: any) => dispatch(action),
            authState
        }),
        [authState]
    );

    useEffect(
        () => {
            const verifyToken = async () => {
                try {
                    const attributes = await Auth.currentUserInfo();
                    
                    if (Object.keys(attributes || {}).length === 0)
                        throw new Error('Unauthenticated');

                    const values = await Auth.currentSession();
                    const payload = values.getIdToken().decodePayload()
                    
                    dispatch({ 
                        type: "SIGN_IN",
                        name: payload.name
                    });
                } catch (err) {
                    dispatch({ type: "SIGN_OUT" } as any)
                }
            }

            verifyToken();
        },
        [history]
    );

    return [authState, authDispatchers];
}