const RESERVATED_SUBDOMAINS = [
    'dev.nirbby.com',
    'qa.nirbby.com',
    'staging.nirbby.com',
    'www.nirbby.com'
];

export const ApplicationConfig = {
    stage: process.env.REACT_APP_ENV || '',
    version: '2.1.0',
    googleClientId: process.env.REACT_APP_G_AUTH_CLIENT_ID,
    url: process.env.REACT_APP_API,
    region: 'us-west-2',
    isCustomSubdomain: /\.nirbby\.com$/.test(window.location.hostname) && !RESERVATED_SUBDOMAINS.includes(window.location.hostname),
    aws: {
        Auth: {
            userPoolId: process.env.REACT_APP_AUTH_ID,
            region: process.env.REACT_APP_AUTH_REGION,
            userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT,
            mandatorySignIn: true,
            authenticationFlowType: 'USER_SRP_AUTH',
            oauth: {
                domain: 'nirbby-dev.auth.us-west-2.amazoncognito.com',
                scope: ['email', 'name', 'phones'],
                redirectSignIn: 'http://localhost:3000/',
                redirectSignOut: 'http://localhost:3000/',
                responseType: 'code',
            },
        }
    },
    mapBoxToken: process.env.REACT_APP_MAP_BOX_TOKEN,
    realtimeApi: process.env.REACT_APP_REALTIME_API,
    googleMapsToken: process.env.REACT_APP_GOOGLE_MAPS_TOKEN,
    stripe: process.env.REACT_APP_STRIPE_KEY,
}