import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'use-http';
import { ApplicationConfig } from './app.config';
import { AuthProvider } from './modules/auth/contexts/auth.context';
import { useAuth } from './modules/auth/hooks/use-auth.hook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CheckoutSession from './modules/checkout-sessions';

const Menu = React.lazy(() => import('./modules/menu/Menu'));
const Landing = React.lazy(() => import('./screens/Landing'));
const NirbbyUserRouter = React.lazy(() => import('./modules/shared/routers/NirbbyUserRouter'));

export default function App() {
  const [_, dispatchers] = useAuth();

  return (
    <AuthProvider value={dispatchers}>
      <Provider url={ApplicationConfig.url}>
        <GoogleOAuthProvider clientId={ApplicationConfig.googleClientId as string}>
          <Suspense fallback={null}>
            {
              ApplicationConfig.isCustomSubdomain ? (
                <Switch>
                  <Route path="/u">
                    <NirbbyUserRouter />
                  </Route>
                  <Route path="/">
                    <Menu />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  <Route path="/u" component={NirbbyUserRouter} />
                  <Route path="/checkout-sessions/:sessionId" component={CheckoutSession} />
                  <Route path="/p/:slug" component={Menu} />
                  <Route path="/e/:slug/:subBranchId" component={Menu} />
                  <Route path="/:place" component={Menu} />
                  <Route path="*" component={Landing} />
                </Switch>
              )
            }
          </Suspense>
        </GoogleOAuthProvider>
      </Provider>
    </AuthProvider>
  );
}