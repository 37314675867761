/* eslint-disable import/first */
import { createTheme, IconButton, ThemeProvider } from '@mui/material';
import {Auth} from '@aws-amplify/auth';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ApplicationConfig } from './app.config';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import './index.css';
import { Close } from '@mui/icons-material';

if (process.env.REACT_APP_ENV === 'prod') {
  window.console.log = () => {};
  console.log = () => {};
}

Auth.configure(ApplicationConfig.aws.Auth);

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071b3'
    },
    secondary: {
      main: '#fff'
    },
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider 
      maxSnack={3} 
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      action={
        (snackbarId: string) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)}>
            <Close htmlColor="#fff" />
          </IconButton>
        )
      }
    >
      <BrowserRouter>
        <App/> 
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root') as HTMLElement, 
);

