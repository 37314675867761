import { useEffect } from "react";
import { ApplicationConfig } from "../../app.config";
import { useParams } from "react-router-dom";
import eventImg from "../checkout-sessions/assets/img/certificadoHumildad.jpg"
import billetLogo from "../checkout-sessions/assets/img/billet-logo.png"

export default function CheckoutSession() {
    const params = useParams<{sessionId: string}>();

    useEffect(
        () => {
            fetch(`${ApplicationConfig.url}/checkout/sessions/${params.sessionId}`)
            .then(async (checkoutSession) => console.log(await checkoutSession.json()))
            .catch((error) => console.log(error));
        },
        []
    );
    return (
        <>
            <div className="w-full h-full lg:flex md:flex">
                <div className="lg:w-2/3 md:w-2/3 w-full h-full flex flex-col">
                    <div className="w-full flex justify-between items-center px-5">
                        <img src={billetLogo} alt="billet" className="h-[60px]"/>
                        <img src={eventImg} alt="eventImage" className="w-[120px]"/>
                    </div>
                    <p>asdasd</p>
                </div>
                <div className="w-1/3 h-full bg-primary flex flex-col p-4">
                    <h2 className="font-title font-bold text-white text-lg"
                    >
                        Mi Orden
                    </h2>
                </div>
            </div>
        </>
    );
}